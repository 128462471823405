<template>
  <v-container>
    <v-card>
      <v-card-title>Neue Nachricht</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="mail.subject"
            label="Betreff"
            outlined
            :rules="validation.subject"
            counter
            dense
            validate-on-blur
          ></v-text-field>
          <v-combobox
            label="Empfänger"
            v-model="mail.to"
            :items="addressList"
            :delimiters="[',', ';']"
            chips
            deletable-chips
            return-object
            outlined
            hide-selected
            multiple
            item-text="text"
            item-value="id"
            @change="
              () => {
                toSearchInput = '';
              }
            "
            :search-input.sync="toSearchInput"
            :rules="validation.to"
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-html="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    [
                      [data.item.zip_code, data.item.city]
                        .filter((e) => e)
                        .join(" "),
                      data.item.email,
                    ]
                      .filter((e) => e)
                      .join(" / ")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-combobox>

          <v-card>
            <v-tabs v-model="editorTab">
              <v-tab href="#editor">Editor</v-tab>
              <v-tab href="#preview">Vorschau</v-tab>
            </v-tabs>
            <v-tabs-items v-model="editorTab">
              <v-tab-item value="editor">
                <div class="pa-2 pt-3">
                  <v-textarea
                    v-model="mail.text"
                    label="Nachricht"
                    outlined
                    :rules="validation.text"
                    counter
                    validate-on-blur
                    rows="10"
                  ></v-textarea>
                  <p>
                    Die Nachricht kann mit
                    <a
                      href="https://cmsstash.de/website-praxis/markdown-fur-webseiten#die-markdown-syntax"
                      target="_blank"
                      >Markdown</a
                    >
                    formatiert werden.
                  </p>
                </div>
              </v-tab-item>
              <v-tab-item value="preview">
                <div
                  v-html="preview"
                  class="ma-2 px-2 py-3 markdownpreview"
                ></div>
                <!-- Dummy div for padding bottom -->
                <div class="pt-1"></div>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
          <v-file-input
            v-model="selectedFiles"
            label="Dateianhänge"
            accept=".pdf,.docx,.xlsx,image/*"
            multiple
            show-size
            outlined
            counter
            class="mt-5"
            :rules="validation.attachments"
            :clearable="false"
          >
            <template v-slot:selection="{ file, index }">
              <v-chip close @click:close="removeAttachment(index)"
                >{{ file.name }} ({{ niceSize(file.size) }})</v-chip
              >
            </template>
          </v-file-input>
          <v-btn @click="sendMail" class="success mt-4">Senden</v-btn>
        </v-form>
      </v-card-text>
      <v-overlay :value="sending" absolute>
        <v-container class="fill-height">
          <v-row align="center" justify="center" class="mb-3">
            Nachricht wird gesendet...
          </v-row>
          <v-row align="center" justify="center">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-row>
        </v-container>
      </v-overlay>
    </v-card>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import uniqWith from 'lodash/uniqWith'
import marked from 'marked'

import { humanFileSize } from '@/utils/files'
import emailService from '@/services/email'
import { createNotification } from '@/utils'
import { usePersonStore } from '@/stores/person'

const EMPTY_MAIL = {
  subject: '',
  to: [],
  text: '',
  html: '',
  attachments: [],
}


export default {
  name: 'MailEditor',
  data() {
    return {
      editorTab: 'editor',
      sending: false,
      mail: cloneDeep(EMPTY_MAIL),
      preview: '',
      validation: {
        subject: [value => value.length >= 5 || 'Mindestens 5 Zeichen sind erforlderlich'],
        to: [
          value => value.length > 0 || 'Mindestens ein Empfänger muss gewählt werden',
          value => {
            const noMail = value.filter(e => e.type === 'person' && !e.email).map(e => e.text)
            if (noMail.length) {
              let errorMsg = `Für die folgenden Mitglieder ist keine Email Adresse hinterlegt: ${noMail.join(', ')}.`
              errorMsg += ' Diese Mitglieder werden deine Nachricht nicht erhalten.'
              return errorMsg
            }
            return true
          },
        ],
        text: [value => value.length >= 10 || 'Mindestend 10 Zeichen sind erforderlich'],
        attachments: [value => value.length <= 10 || 'Maximal 10 Dateien erlaubt'],
      },
      toSearchInput: '',
      mailingLists: [],
    }
  },
  computed: {
    compiledMarkdown() {
      return marked(this.mail.text)
    },
    addressList() {
      const mailingLists = JSON.parse(JSON.stringify(this.mailingLists)).map(e => {
        e.text = e.name
        e.email = e.address
        e.type = 'list'
        return e
      })
      const persons = usePersonStore().personList.map(e => {
        e = JSON.parse(JSON.stringify(e))
        const obj = {
          text: e.name,
          id: e.id,
          email: e.email,
          zip_code: e.zip_code,
          city: e.city,
          type: 'person',
        }
        return obj
      })
      return [
        { header: 'Mailing Listen' },
        ...mailingLists,
        { divider: true },
        { header: 'Personen' },
        ...persons,
      ]
    },
    selectedFiles: {
      get() {
        return this.mail.attachments
      },
      set(val) {
        // Set the attachments, remove any duplicates (by name and size)
        this.mail.attachments = uniqWith(
          [...this.mail.attachments, ...val],
          (arrVal, othVal) => arrVal.name === othVal.name && arrVal.size === othVal.size,
        )
      },
    },
  },
  methods: {
    prepareMail() {
      const mail = cloneDeep(this.mail)
      mail.html = this.compiledMarkdown
      mail.to = mail.to.map(e => (typeof e === 'object' ? e : { email: e })).filter(e => e.email).map(e => e.email)
      return mail
    },
    sendMail() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.sending = true
      const mail = this.prepareMail()
      emailService.sendMail(mail)
        .then(response => {
          createNotification('Nachricht gesendet!', 'success')
          this.mail = cloneDeep(EMPTY_MAIL)
          this.$refs.form.resetValidation()
        })
        .catch(error => {
          createNotification('Nachricht konnte nicht gesendet werden!', 'error')
          throw error
        })
        .finally(() => {
          this.sending = false
        })
    },
    loadMailingLists() {
      return emailService.getMailingLists()
        .then(data => {
          this.mailingLists = data
        })
    },
    removeAttachment(index) {
      const files = this.mail.attachments
      files.splice(index, 1)
      this.mail.attachments = files
    },
    niceSize(value) { return humanFileSize(value) },
  },
  watch: {
    editorTab(newVal) {
      console.log(newVal)
      if (newVal === 'preview') {
        console.log('Fetching Preview...')
        emailService.previewMail(this.prepareMail()).then(data => {
          this.preview = data.preview
        })
      } else {
        this.preview = ''
      }
    },
  },
  mounted() {
    this.loadMailingLists()
  },
}
</script>

<style lang="scss" scoped>
.markdownpreview {
  min-height: 250px;
  border: 1px solid #78909c; // blue-grey lighten-1
  background-color: #fafafa; // grey lighten-5
  border-radius: 3px;
}
</style>
