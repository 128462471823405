function humanFileSize(bytes, si = true) {
  var thresh = si ? 1000 : 1024
  if(Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }
  var units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  var u = -1
  do {
    bytes /= thresh
    ++u
  } while(Math.abs(bytes) >= thresh && u < units.length - 1)
  return bytes.toFixed(1) + ' ' + units[u]
}


async function loadFile(src, useFileReader = true) {
  if (useFileReader || typeof (URL) === 'undefined') {
    const reader = new FileReader()
    return new Promise(resolve => {
      reader.onloadend = (evt) => {
        const dataUrl = evt.target.result
        resolve(dataUrl)
      }
      reader.readAsDataURL(src)
    })
  } else {
    return new Promise(resolve => {
      const objUrl = URL.createObjectURL(src)
      resolve(objUrl)
    })
  }
}

async function dataURItoBlob(uri) {
  return (await fetch(uri)).blob()
}

export {
  humanFileSize,
  loadFile,
  dataURItoBlob,
}
